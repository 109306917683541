.component.component-text,
.component.component-headlineText {

  ul li.plus {

    list-style-type: none;
    padding: 0;
    margin-left: -40px;
    span {
      margin-right: 3px;
    }

    svg {
      height: 15px;
      width: 15px;
      overflow: visible;
      margin-top: -5px;
    }

  }

  a {
    color: $color-red;
    font-family: 'CenturyGothicStd-Bold';
    font-weight: 300;
  }

  table {
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin-bottom: 8px;
  }
  table tr:nth-of-type(even) {
    background-color: #e8e8e8;
  }
  table tr td {
    border-right: 1px solid #fff;
    border-top: none;
    padding: 8px;
    line-height: 1.428571429;
    vertical-align: top;
    
  }

}

.component-headlineText.mb-5 + .component-downloads {
    margin-top: -3rem!important;
}
.component-headlineText.mb-5.bg-css-grey + .component-downloads {
    margin-top: 0!important;
}
