.body-sectionWebsite {
  .page-area-main {

    //margin-top: 6rem;

    .gvbs-section-text-v1 .col {

      margin-bottom: 5.5rem;

      p {
        margin: 3rem 0;
        margin-bottom: 0;
      }

    }

    .gvbs-section-text-v1 .col:last-child {
      margin-bottom: 2.5rem;
    }

    .textBackground-grey {
      font-family: CenturyGothicStd-Bold,Open Sans,Arial,sans-serif;
      padding: 1rem 2rem;
      border-radius: inherit;
      line-height: 2.7rem;
      background-color: #eee;
    }


  }
}