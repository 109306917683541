.container-video {

  background-color: rgb(221,221,221);

  video {

    width: 100%;

  }

}