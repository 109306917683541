.container-search {
  height: 235px;
  background-color: rgb(221,221,221);

  h2 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 18px;
    text-transform: uppercase;
  }

  .title-v1 {

  z-index: 1;
  position: relative;
  text-align: center;
  margin-bottom: 60px;
  }
  .title-v1 .title-v2:after {

    margin-left: -35px;

    width: 70px;
    height: 2px;
    content: " ";
    bottom: -10px;
    background: #c5282c;
    position: absolute;

    left: 50%;
  }

  button {
    color: #fff;
    text-decoration: none;
    border: 0;
    font-size: 14px;
    cursor: pointer;
    padding: 0;
    position: relative;
    background: #c5282c;
    white-space: nowrap;
    display: inline-block;
    width: 34px;
    height: 34px;
    svg {
      width: 16px;
      height: 16px;
    }
  }

}
.container-results {

  .results-number{
    color: #999;
    display: block;
    margin-top: 30px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  .results {
    list-style-type: none;
    padding:0;
    li {
      margin-bottom: 15px;
    }
    h3 {
      font-weight: bold;
    }
    svg {
      width: 12px;
      height: 16px;
      margin-top: -2px;
      margin-right: 3px;
    }
    small {
      margin-left: 20px;
    }

  }

}