#team {

  .team-filter {
    color: #666;
    text-transform: uppercase;
    cursor: pointer;
    padding-right: 12px;
    transition: all .3s ease-in-out;
    position: relative;
    overflow: visible;
    margin: 0 0 10px;
    display: inline-block;
  }
  
  .team-filter.active {
    color: $color-red;
  }

  .team-item {

    margin-bottom: 30px;

    img {
      margin-bottom: 20px;
    }

    .team-title {
      color: #555;
      font-size: 1.5rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-family: CenturyGothicStd,Open Sans,Arial,sans-serif;
      font-weight: 700;
    }
    h4 {
      text-transform: uppercase;
      font-size: 1.4rem;
      color: #666;
      vertical-align: middle;
      min-height: 5rem;
      min-height: 2rem;
      white-space: normal;
      position: relative;
      margin-bottom: 10px;
    }
    h4:after {
      left: 0;
      width: 70px;
      height: 1px;
      content: " ";
      bottom: -10px;
      margin-bottom: 5px;
      background: #666;
      position: absolute;
    }
    a {
      color: #c5282c;
      font-family: CenturyGothicStd-Bold,Open Sans,Arial,sans-serif;
      text-decoration: none;
    }

  }

}