nav {

  border-top: 1px solid #ddd;
  background-color: #f7f7f7;
  min-height: 51px;

  .navbar-title {
    text-transform: uppercase;
    color: #d0c7c7;
    font-size: 2rem;
    padding-top: .95rem;
    line-height: 2.4rem;
  }
  .navbar-toggle {
    margin-right: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    position: relative;
    float: right;
    border: 1px solid transparent;
    border-radius: 0;
    outline: 0!important;
    border-color: #942a2e;
    background: $color-red;
    padding: 6px 10px 2px;
    height: 34px;
    width: 34px;
    svg {
      margin-top: -2px;
    }
  }
  .navbar-toggle:hover {
    background: #942a2e!important;
    padding: 6px 10px 2px;
  }

  .mega-menu {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 hsla(0,0%,100%,.1);
    .container {
      padding-left: 0;
      padding-right: 0;
    }
    .lvl1 {
      font-size: 14px;
      padding: 9px 10px;
      padding-left: 10px;
      /*padding-top: 0;*/
      line-height: 20px;
      display: block;
      svg {
        display: none;
      }
    }
    li.active .lvl1{
      background: $color-red;
      color: #fff;
    }

    .dropdown-menu {

      z-index: 1000;
      float: left;
      min-width: 160px;
      padding: 5px 0;
      margin: 2px 0 0;
      font-size: 14px;
      text-align: left;
      list-style: none;
      background-color: #fff;
      box-shadow: 0 6px 12px rgba(0,0,0,.175);


      position: static;
      display: block;
      width: 100%;
      padding: 0;
      margin-bottom: 15px;
      border: none;
      margin-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      a {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.428571429;
        color: #333;
        white-space: nowrap;
        border-bottom: 1px solid #eee;
        padding-left: 20px;
      }

    }
  }

  .mega-menu li.dropdown .dropdown-menu .dropdown-menu {
    box-shadow:none;
    margin-bottom: 2px;
  }
  .mega-menu li.dropdown .dropdown-menu .team-filter {
    margin-left:10px;
  }

  .mega-menu.collapse.show {
    display: block!important;
    overflow-y: auto!important;
  }


  @media only screen and (min-width: 992px) {
    
    .mega-menu li.dropdown .dropdown-menu .team-filter {
      margin-left:0;
    }

    .mega-menu.collapse {
      display: block !important;
      height: auto !important;
      padding-bottom: 0;
      overflow: visible !important;

      .nav.navbar-nav {
        display:block;

        .dropdown {
          float: left;
          display: inline-block;
          a.lvl1 {
            padding: 9px 25px 9px 10px;
            display: inline-block;
          }
        }

      }

    }

    .mega-menu li.dropdown .dropdown-menu .dropdown-submenu {
      .dropdown-menu {
        position: absolute;
        top: auto;
        left: 100%;
        margin-top: -35px;
        display: none!important;
      }
    }
    .mega-menu li.dropdown .dropdown-menu .dropdown-submenu:hover {
      .dropdown-menu {
        position: absolute;
        top: auto;
        left: 100%;
        margin-top: -35px;
        display: block!important;
      }
    }

  }

  .search {
    padding: 5px 0;

    .btn-u {
      border: 0;
      font-size: 14px;
      cursor: pointer;
      padding: 6px 13px;
      position: relative;
      background: $color-red;
      white-space: nowrap;
      display: inline-block;
      color: #fff;
      text-decoration: none;

      svg {
        height: 14px;
        width: 14px;
        fill: #fff;
      }
    }
  }
}


@media only screen and (min-width: 992px) {

  nav {
    background: #fff;
    min-height: 41px;
  }

  nav .mega-menu li .lvl1 {
    svg {
      display: inline;
      width: 8px;
      height: 12.8px;
      margin-bottom: 3px;
      margin-left: 3px;
    }
  }
  nav .mega-menu li.dropdown:hover {
    background-color: transparent;
    border-bottom: 2px solid #c5282c;
    color: #c5282c;
    position: relative;
    text-decoration: none;
    .lvl1 {
      border-bottom: 0;
      text-decoration: none;
    }
    .dropdown-menu {
      border-top: 2px solid #c5282c;
      display:block!important;
      position: absolute;
      width: auto;
      min-width:200px;
      border-top: 2px solid #c5282c;
      border-bottom: 2px solid #687074;
      a {
        display: block;
        clear: both;
        font-weight: 400;
        line-height: 1.428571429;
        white-space: nowrap;

        color: #687074;
        font-size: 13px;
        border-bottom: 1px solid #eee;
        text-decoration: none;
        padding-top: 6px;
        padding-right: 15px;
        padding-bottom: 6px;
        padding-left: 15px;
      }
      a:hover {
        background-color: #c5282c!important;
        color: #fff;
        text-decoration: none;
      }

    }
  }
  nav .mega-menu li.active .lvl1 {
    background-color: transparent;
    border-bottom: 2px solid #c5282c;
    color: #c5282c;
    position: relative;
    text-decoration: none;
  }
  
}

.form-control {
  height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: none;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  font-size: 14px;
  line-height: 1.428571429;
  color: #555;
}


.search-toggle {
  background: #ffffff!important;
  color: #4d4d4d;
  position: relative;
  float: right;
  border: 1px solid transparent;
  border-radius: 0;
  outline: 0!important;
  padding: 0;
  height: 40px;
  width: 40px;
  svg {
    width: 16px;
    height: 16px;
  }
  .icon-times {
    display: inline;
  }
  .icon-search {
    display: none;
  }
}
.search-toggle:hover {
  color: #c51617!important;
  border-bottom: 2px solid #c51617;
}
.search-toggle.collapsed {
  .icon-times {
    display: none;
  }
  .icon-search {
    display: inline;
  }
}

#searchDesktop {

  right: 0;
  top: 40px;
  padding: 14px;
  position: absolute;
  background: #fcfcfc;
  border-top: 2px solid #eee;
  box-shadow: 0 1px 3px #ddd;
  transition:none;
  width:330px;

  button {
    color: #fff;
    text-decoration: none;
    border: 0;
    font-size: 14px;
    cursor: pointer;
    padding: 0;
    position: relative;
    background: #c5282c;
    white-space: nowrap;
    display: inline-block;
    width: 34px;
    height: 34px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
