
@font-face {
  font-family: 'CenturyGothicStd-Italic';
  src: url('/fonts/32A018_0_0.eot');
  src: url('/fonts/32A018_0_0.eot?#iefix') format('embedded-opentype'),url('/fonts/32A018_0_0.woff2') format('woff2'),url('/fonts/32A018_0_0.woff') format('woff'),url('/fonts/32A018_0_0.ttf') format('truetype');
}
@font-face {
  font-family: 'CenturyGothicStd-BoldItalic';
  src: url('/fonts/32A018_1_0.eot');
  src: url('/fonts/32A018_1_0.eot?#iefix') format('embedded-opentype'),url('/fonts/32A018_1_0.woff2') format('woff2'),url('/fonts/32A018_1_0.woff') format('woff'),url('/fonts/32A018_1_0.ttf') format('truetype');
}
@font-face {
  font-family: 'CenturyGothicStd-Bold';
  src: url('/fonts/32A018_2_0.eot');
  src: url('/fonts/32A018_2_0.eot?#iefix') format('embedded-opentype'),url('/fonts/32A018_2_0.woff2') format('woff2'),url('/fonts/32A018_2_0.woff') format('woff'),url('/fonts/32A018_2_0.ttf') format('truetype');
}
@font-face {
  font-family: 'CenturyGothicStd';
  src: url('/fonts/32A018_3_0.eot');
  src: url('/fonts/32A018_3_0.eot?#iefix') format('embedded-opentype'),url('/fonts/32A018_3_0.woff2') format('woff2'),url('/fonts/32A018_3_0.woff') format('woff'),url('/fonts/32A018_3_0.ttf') format('truetype');
}

h2.title-v2 {
  color: #555;
  position: relative;
  letter-spacing: .15rem;
}
h2.title-v2 {
  font-size: 1.8rem;
  margin-bottom: 4rem;
}
h2.title-v2:after {
  left: 0;
  width: 70px;
  height: 2px;
  content: " ";
  bottom: -10px;
  background: $color-red;
  position: absolute;
}
h1 {
  line-height: 35px;
}
.title-v1 h3, .title-v2 {
  text-transform: uppercase;
  word-wrap: break-word;
}
h2 {
  font-size: 1.8rem;
  line-height: 2.4rem;
}
h1, h2, h3, h4, h5, h6 {
  color: #555;
  margin-top: 5px;
  text-shadow: none;
  font-family: CenturyGothicStd,Open Sans,Arial,sans-serif;
}
b, strong {
  font-family: CenturyGothicStd-Bold;
}
p {
  font-size:  1.55rem;
  color: #555;
  font-weight: 300;
}
label, li, li a, p {
  color: #555;
}
.btn {
  box-shadow: none;
}
.btn.btn-danger {
  padding: 1.5rem 3.5rem;
  border-radius: 1.4rem;
  border-width: .2rem;
  white-space: inherit;
  background-color: #c51617;
  font-family: CenturyGothicStd,Open Sans,Arial,sans-serif;
}
.btn.btn-danger, .btn.btn-danger:hover {
  text-transform: uppercase;
  letter-spacing: .2rem;
  border-color: #c51617;
  color: #fff;
}
a.btn {
  font-size: 14px;
}