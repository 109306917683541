.component.component-form {

  padding-left: 15px;
  padding-right: 15px;

  h2 {
    margin-top: 5px;
    line-height: 2.4rem;
    background-color: #c21a20;
    padding: .8rem 1.8rem .3rem;
    text-transform: uppercase;
    color: #fff;
    font-size: 1.8rem;
    letter-spacing: .17rem;
    width: 100%;
  }

  .freeform-row .freeform-column {
    padding-bottom: 15px;
    display: block;

    @media screen and (min-width: 768px) {
      display: flex;
    }
  }

  .freeform-row .freeform-column .freeform-label {
    font-family: CenturyGothicStd,Open Sans,Arial,sans-serif!important;
    color: #555;
    font-weight: 400;
    width: 30%;
    padding-right: 1.5rem;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .freeform-row .freeform-column .radio-group-label {

    /*
    width: 23%;
    */
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .freeform-row .freeform-column .freeform-label.freeform-required:after {
    content: "*";
    margin-left: 5px;
    color: #555;
  }

  select, input, textarea {

    display: block;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555;

    border-radius: 0;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    background-color: #fff;
    border: 1px solid #ccc;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  }
  textarea {
    height: auto;
  }

  .freeform-column.freeform-col-elementarschaden {
    label {

    }
    label.freeform-label {

    }
  }



  .custom-control-label {
    left: 15px;
  }
  .custom-control-label:before {
    top: 0;
    left: -30px;
  }

  .custom-checkbox .custom-control-label:before,
  .custom-radio .custom-control-label:before {
    height: 22px;
    width: 22px;
  }
  .custom-checkbox .custom-control-label:before {
    border-radius:0;
  }
  .custom-checkbox .custom-control-label:after,
  .custom-radio .custom-control-label:after {
    height: 22px;
    width: 22px;
    top: 0;
    left: -30px;
  }
  .custom-checkbox .custom-control-input:checked~.custom-control-label::before,
  .custom-radio .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    background-color: #c21a20;
  }
  .custom-radio .custom-control-input:checked~.custom-control-label:after {
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");
  }
  .custom-control.custom-radio,
  .custom-control.custom-checkbox {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 5px;
    width: 200px;
    
    &.field-elementarschaden,
    &.field-feuerschaden,
    &.field-beschaedigteGebaeudeteile,
    &.field-kategorie {
      width: 250px;
    }

    &.field-offertenWerdenEingeholt,
    &.field-abweichendeKontaktperson{
      width: 100%;
      margin-left:5px;
    }
  }

  .custom-control.full-checkbox  {
    width: auto;
  }

  .custom-control.custom-radio {
    width: 150px;
    margin-left:3px;
  }

  .field-automatischeAlarmUndLoscheinrichtungenAmLagerungsort {
    width: 256px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .file-footer-buttons {
    .kv-file-upload.btn {
      display: none;
    }

  }
  .file-input {
    background-color: #e8e8e8;
    border: 1px solid #bfbfbf;
    padding-bottom: 2rem;
    margin-bottom: 15px;
    width: 100%;

    .file-drop-zone.clickable {
      border: 2px dashed #999;
    }

    .file-preview {
      border:0;
      padding: 5px;
      width: 100%;
      margin-bottom: 5px;
    }

    .btn.btn-file {
      background-color: #5d5d5d;
      border: 0;
      width: 50px;
    }
    .btn.btn-file img {
      width: 32px;
      height: 32px;
    }
    
    .file-preview-frame {
      background-color: #ffffff !important;
    }
    .kv-file-remove.btn,
    .kv-file-zoom.btn {
      width: 25px;
      height: 25px;
      position: relative;
      i {
        background-size: 15px;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        top: 0;
        left: 0;
        position: absolute;
      }
    }

    .kv-file-remove.btn {
      i {
        background-image: url(/icons/trash-solid.svg);
      }
    }
    .kv-file-remove.btn {
      display: none;
    }
    .kv-file-zoom.btn {
      i {
        background-image: url(/icons/search-solid.svg);
      }
    }

  }

  .submit {

    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;

    color: #fff;
    background-color: #6da82c;
    text-transform: uppercase;
    letter-spacing: .1rem;
    font-family: CenturyGothicStd,Open Sans,Arial,sans-serif;
    border:0;

    box-shadow: none;

  }
  .submit.disabled {
    background-color: rgba(109, 168, 44, 0.5);
  }

  .freeform-form-has-errors {
    li {
      margin-bottom: 10px;
    }
    .minus svg {
        height: 15px;
    }
  }


  @media screen and (min-width: 768px) {

    .freeform-row .freeform-column.freeform-col-anredeKontaktperson {
      .form-group.radio-group-label {
        float: left;
      }
    }

    .freeform-row .freeform-column.freeform-col-nachnameKontaktperson,
    .freeform-row .freeform-column.freeform-col-vornameKontaktperson,
    .freeform-row .freeform-column.freeform-col-strasseNrKontaktperson,
    .freeform-row .freeform-column.freeform-col-plzOrtKontaktperson,
    .freeform-row .freeform-column.freeform-col-kontaktperson,
    .freeform-row .freeform-column.freeform-col-eMailAdresseKontaktperson,
    .freeform-row .freeform-column.freeform-col-telefonKontaktperson,
    .freeform-row .freeform-column.freeform-col-telefon2Kontaktperson,
    .freeform-row .freeform-column.freeform-col-firmaKontaktperson {
      clear: both;
      .freeform-label {
        float: left;
        width: 23%;
      }
      .freeform-input {
        float: right;
        width: 77%;
        margin-bottom: 15px;
      }
    }
    .freeform-row .freeform-column.freeform-col-kategorie {

      .form-group.checkbox-group-label {
        float: left;
        width: 30%;
      }
      .checkbox-group-checkboxes {
        float: left;
        width: 100%;
        margin-bottom: 15px;
      }
    }

  }

}
.component.component-form {
  .freeform-row .freeform-column.freeform-col-strasseSchadensortHidden,
  .freeform-row .freeform-column.freeform-col-strasseLagerungsadresseHidden,
  .freeform-row .freeform-column.freeform-col-strasseVerkaufsadresseHidden,
  .freeform-row .freeform-column.freeform-col-strasseLiegenschaftsortHidden {
    display: none;
  }
}

.file-input .file-preview .fileinput-remove {
  top: auto;
  right: 20px;
  height: 20px;
  bottom: -20px;
}

#f3 {
  position:relative;
  top: -150px;
}

.ff-form-success {
  margin-top:100px;
  margin-bottom:100px;

  padding: 1rem 2rem;
  border-radius: inherit;
  line-height: 2.7rem;
  background-color: #eee;

  p {
    font-weight: bold;

    margin-top: 5px;
    line-height: 2.4rem;
    background-color: #c21a20;
    padding: .8rem 1.8rem .3rem;
    text-transform: uppercase;
    color: #fff;
    font-size: 1.8rem;
    letter-spacing: .17rem;
    width: 100%;

  }
}

#formanchor {
  margin-top:-150px;
  position: absolute;
}

/**
 * FilePond Custom Styles
 */
.filepond--drop-label {
  color: #4c4e53;
}

.filepond--label-action {
  -webkit-text-decoration-color: #babdc0;
  text-decoration-color: #babdc0;
}

.filepond--panel-root {
  border-radius: 2em;
  background-color: #edf0f4;
  height: 1em;
}

.filepond--item-panel {
  background-color: #595e68;
}

.filepond--drip-blob {
  background-color: #7f8a9a;
}



.component.component-form .freeform-row {
  .freeform-col-elementarschaden,
  .freeform-col-unterlagen,
  .freeform-col-beschaedigteGebaeudeteile,
  .freeform-col-feuerschaden,
  .freeform-col-offertenWerdenEingeholt,
  .freeform-col-abweichendeKontaktperson,
  .freeform-col-automatischeAlarmUndLoscheinrichtungenAmLagerungsort,
  .freeform-col-kategorie {
    label {
      width: 240px;
      margin-left: 30px;
    }
    input {
      display: inline-block;
      height: 22px;
      width: 22px;
      margin-left: -30px;
      position: absolute;
    }
  }
}
.freeform-label-bestaetigungzumMerkblatt {
  margin-left: 30px;
  #form-input-bestaetigungzumMerkblatt {
    display: inline-block;
    height: 22px;
    width: 22px;
    margin-left: -30px;
    position: absolute;
  }
}
.component.component-form .freeform-row {
  .freeform-col-anrede,
  .freeform-col-anredeKontaktperson {
    label {
      width: 150px;
      margin-left: 30px;
    }
    input {
      display: inline-block;
      height: 22px;
      width: 22px;
      margin-left: -30px;
      position: absolute;
    }
  }

}

.component.component-form .freeform-row .freeform-column.freeform-col-automatischeAlarmUndLoscheinrichtungenAmLagerungsort,
.component.component-form .freeform-row .freeform-column.freeform-col-kategorie,
.component.component-form .freeform-row .freeform-column.freeform-col-bestaetigungzumMerkblatt {
  display: block;
}

.component.component-form .freeform-row .freeform-column {
  .freeform-label.freeform-label-elementarschaden,
  .freeform-label.freeform-label-beschaedigteGebaeudeteile,
  .freeform-label.freeform-label-feuerschaden,
  .freeform-label.freeform-label-automatischeAlarmUndLoscheinrichtungenAmLagerungsort,
  .freeform-label.freeform-label-kategorie {
    width: 100%;
    margin:0;
    margin-top: 5px;
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 768px) {
  .freeform-col-offertenWerdenEingeholt,
  .freeform-col-abweichendeKontaktperson {
    width: 70%;
    margin-left: 23%;
  }

  .component.component-form .freeform-row .freeform-column .freeform-label.freeform-label-offertenWerdenEingeholt,
  .component.component-form .freeform-row .freeform-column .freeform-label.freeform-label-abweichendeKontaktperson {
    width: 100%;
  }

  .component.component-form .freeform-row .freeform-col-anrede .freeform-label-anrede {
    margin:0;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .component.component-form .freeform-row .freeform-col-anredeKontaktperson .freeform-label-anredeKontaktperson {
    width: 23%;
    margin:0;
    margin-top: 5px;
    margin-bottom: 15px;
  }
}

.component.component-form .freeform-row .freeform-column.freeform-col-anderes {
  height: 70px;
  label {
    width: 23%;
    float: left;
  }
  textarea {
    width: 77%;
    float: right;
  }
}

.component.component-form .freeform-row .freeform-column.freeform-col-bestaetigungzumMerkblatt {
  label {
    float: left;
  }
  input {
    width: auto;
  }
}


.freeform-col-html {
  clear: both;
}

.component.component-form .freeform-row .freeform-column.freeform-col-upload {
  display: block;
}
.filepond--credits {
  display: none;
}
.filepond--root {

  font-family: CenturyGothicStd,Open Sans,Arial,sans-serif!important;
  color: #555;
  font-weight: 400;

  font-size: 20px !important;

}

/* use a hand cursor intead of arrow for the action buttons */
.filepond--file-action-button {
  cursor: pointer;
}

/* the text color of the drop label*/
.filepond--drop-label {
  color: #555;
}

/* underline color for "Browse" button */
.filepond--label-action {
  text-decoration-color: #aaa;
}

/* the background color of the filepond drop area */
.filepond--panel-root {
  background-color: #eee;
}

/* the border radius of the drop area */
.filepond--panel-root {
  border-radius: 0.5em;
}

/* the border radius of the file item */
.filepond--item-panel {
  border-radius: 0.5em;
}

/* the background color of the file and file panel (used when dropping an image) */
.filepond--item-panel {
  background-color: #555;
}

/* the background color of the drop circle */
.filepond--drip-blob {
  background-color: #999;
}

/* the background color of the black action buttons */
.filepond--file-action-button {
  background-color: rgba(0, 0, 0, 0.5);
}

/* the icon color of the black action buttons */
.filepond--file-action-button {
  color: white;
}

/* the color of the focus ring */
.filepond--file-action-button:hover,
.filepond--file-action-button:focus {
  box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.9);
}

/* the text color of the file status and info labels */
.filepond--file {
  color: white;
}

/* error state color */
[data-filepond-item-state*='error'] .filepond--item-panel,
[data-filepond-item-state*='invalid'] .filepond--item-panel {
  background-color: red;
}

[data-filepond-item-state='processing-complete'] .filepond--item-panel {
  background-color: green;
}

/* bordered drop area */
.filepond--panel-root {
  background-color: transparent;
  border: 2px solid #2c3340;
}


.freeform-column {
  flex-wrap: wrap;
}
.freeform-column label {
  flex: 1 0 23%;
}
.freeform-column .form-control {
  flex: 1 0 77%;
}
.freeform-column ul.ff-errors {
  flex-basis: 77%;
  display: flex;
  margin-left: 23%;
  padding: 0;
}
.freeform-column.freeform-col-feuerschaden ul.ff-errors,
.freeform-column.freeform-col-elementarschaden ul.ff-errors,
.freeform-column.freeform-col-beschaedigteGebaeudeteile ul.ff-errors {
  margin-left: 0;
}


.freeform-column ul.ff-errors li {
  color: red;
  list-style-type:none;
  padding-top: 10px;
}
.component.component-form .form-control.ff-has-errors {
  border: 1px solid red;
}

.freeform-column.freeform-col-anrede,
.freeform-column.freeform-col-anredeKontaktperson {
  flex-wrap: unset;
}


.freeform-column.freeform-col-anrede ul.ff-errors {
  position: absolute;
  margin-top: 20px;
  margin-left: calc(23% - 6px);
}


.freeform-column.freeform-col-anrede ul.ff-errors {
  position: absolute;
  margin-top: 20px;
  margin-left: calc(23% - 6px);
}


.freeform-column.freeform-col-anrede .ff-has-errors:after {
  width: 22px;
  height: 22px;
  border-radius: 22px;
  top: 0;
  left: 0;
  position: relative;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid red;
}

.freeform-column.freeform-col-feuerschaden .ff-has-errors:after,
.freeform-column.freeform-col-elementarschaden .ff-has-errors:after,
.freeform-column.freeform-col-beschaedigteGebaeudeteile .ff-has-errors:after {
  width: 22px;
  height: 22px;
  top: 0;
  left: 0;
  position: relative;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid red;
}

.ff-form-errors {
  border: 2px solid red;
  padding:10px;
  margin-bottom: 30px;
}

.ff-form-errors p {
  margin: 0;
  font-weight: bold;
  font-size: 18px;
}
.filepond {
  cursor: pointer;
  //min-height: 71px;
}
.filepond--drop-label label {
  cursor: pointer;
}

.freeform-label-kontrollnummer,
.freeform-label-gebaudeeigentumerschaft {
    hyphens: auto;
    hyphenate-limit-chars: 12 4 4;
}
@media screen and (min-width: 768px) {
    .component.component-form .freeform-row .freeform-column .freeform-label-parzellennummer,
    .component.component-form .freeform-row .freeform-column .freeform-label-sektion {
        float: left;
        width: 23%;
    }
    .component.component-form .freeform-row .freeform-column .freeform-label-parzellennummer + input,
    .component.component-form .freeform-row .freeform-column .freeform-label-sektion + input {
        width: 77%;
    }
}
