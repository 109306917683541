.brand {
  height: 72px;
}

header {
  box-shadow: 1px 1px 20px 10px #c8c8c8;
  transition: all .2s ease-in-out;
  width: 100%;
  position: relative;
  a {
    font-size: 16px;
  }
  svg.logo {
    width: 47px;
    height: 54.52px;
    margin-right: -7px;
    @media only screen and (min-width: 992px) {
      width: calc(47px * 1.2);
      height: calc(54.52px * 1.2);
    }
  }
}

@media only screen and (min-width: 992px) {
  header.header-sticky {
    background: #ffffff;
    height: 114px;
    left: 0;
    margin-top: 0;
    position: fixed;
    top: 0;
    transition: all .2s ease-in-out;
    z-index:10;
  }
  main {
    margin-top: 114px;
    z-index:0;
  }
}

.page-area-teaser {
  background-color: #d9d9d9;
  .bgimg {
    background-position: left center;

    @media only screen and (min-width: 768px) {
      background-position: 50%;
      background-position-x: 50%;
      background-position-y: center;
    }
    @media only screen and (min-width: 1200px) {
    //  background-position: left center;
    }
    
  }
}

.header-teaser {

  background-size: initial;
  padding-top: 0;
  margin-top: 0;

  height: 17rem;

  @media only screen and (min-width: 768px) {
    height: 34rem;
  }

  .text-wrapper {
    margin:auto;
    h1 {
      font-size: 2.5rem;
      margin: 0;
      padding: 0;

      @media only screen and (min-width: 768px) {
        font-size: 4rem;
        line-height: 35px;
      }
    }
    h2 {
      font-size: 1.5rem;
      line-height: 2.4rem;
      margin: 0;
      padding: 0;
      @media only screen and (min-width: 768px) {
        font-size: 2rem;
        margin-top: 5px;
      }
    }
  }
}