.component.component-faq {

  .card {

    margin-bottom: 5px;
    border-radius: 0;

    .card-header {

      margin-top: 0;
      margin-bottom: 0;
      border-radius: 0;
      padding: .75rem 1.25rem;
      border: 0;

      button {
        font-size: 16px;
        line-height: 25px;
        color: $color-font;
        text-align: left;
        font-weight: 500;
        font-family: 'CenturyGothicStd-Bold';
        width: 100%;
      }

    }
    .card-body {
      border-top: 1px solid rgba(0,0,0,.125);
    }

  }

}
