.debug {
  background-color: black;
  color: lime;
  border: 1px solid lime;
  padding:5px;
  margin-top:30px;
  margin-bottom:15px;
 // font-family: "Consolas", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
}

@font-face {
  font-family: 'CenturyGothicStd';
  src: url('/fonts/32A018_3_0.eot');
  src: url('/fonts/32A018_3_0.eot?#iefix') format('embedded-opentype'),url('/fonts/32A018_3_0.woff2') format('woff2'),url('/fonts/32A018_3_0.woff') format('woff'),url('/fonts/32A018_3_0.ttf') format('truetype');
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}
body {
  background-color: $color-white;
  color: $color-white;
  color: $color-font;
  font-size: 1.5rem;
  line-height: 1.6;
  font-family: 'CenturyGothicStd';

}
a, a:hover {
  text-decoration: none;
  color: $color-font;
}
a:hover {
  text-decoration: underline;
}
a img {
  text-decoration: none;
}
img {
  max-width: 100%;
}
.list-unstyled {
  list-style: none;
}
.pull-left {
  float: left!important;
}

#video.section {
  padding:0;
  margin: 0;
  line-height: 0;
  iframe {
    width: 100%;
    border: 0;
  }
}

.plus-arrow {
  svg {
    margin-left: 2px;
  }
  span {
    margin-right: 1px;
  }
}
.link-extern,
.link-download {
  color: $color-red;
  font-family: CenturyGothicStd-Bold,Open Sans,Arial,sans-serif;
  svg {
    height: 15px;
    width: 15px;
    overflow: visible;
    margin-top: -5px;
    margin-left: 5px;
  }
}
.link-download svg {
  margin-left: 0px;
  margin-right: 5px;
 }
#gebietseinteilung {
  height: 470px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  @media only screen and (min-width: 992px) {
    a {
      width: 50%;
      margin: auto;
    }
  }
}

.bgimg {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.anchor {
  position: absolute;
  margin-top: -120px;
}
.lh-0 {
  line-height: 0;
}
.red {
  color: $color-red;
}
.color-font {
  color: $color-font;
}

#topcontrol {
  position: fixed;
  opacity: 0;
  cursor: pointer;
  color: #fff;
  z-index: 99;
  width: 30px;
  height: 30px;
  font-size: 20px;
  background: #424242;
  right: 14px;
  bottom: 11px;
  border-radius: 3px;
  display: flex;
  transition: all .3s ease-in-out;
  svg {
    width: 12px;
    height: 19.19px;
    margin:auto;
  }
}
#topcontrol.active {
  opacity: 1;
}