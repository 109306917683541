footer {

  padding: 40px 0;
  background: #272727;
  font-size: 1.2rem;
  background-color: #424242;

  color: #c8c8c8;
  .headline {
    display: block;
    margin: 10px 0 25px;
  }
  address {
     line-height: 23px;
  }
  address {
    margin-bottom: 20px;
    font-style: normal;
  }
  h2 {
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    border: none;
  }
  a {
    color: #f7f7f7;
  }
  a:hover {
    color: #f7f7f7;
  }
  .md-margin-bottom-40 {
    margin-bottom: 40px;
  }

  a.download {
    display: inline-block;
    margin-left:20px;
    svg {
      margin-left:-20px
    }
    small {
      color: #c8c8c8;
    }
  }


  hr {
    width: 100%;
    box-sizing: content-box;
    height: 0;
    border: 0;
    border-top: 1px solid #eee;
    border-color: #4c4c4c;
    border-width: 2px;
    margin: 30px 0;
    margin-top: 0;

  }

  .partner-list {
    svg {
      width: 11px;
      height: 11px;
      margin-left:2px;
    }
    li {
      margin-bottom: 7px;
    }
  }

  .latest-list {
    svg {
      width: 12px;
      height: 16px;
      margin-top: -2px;
      margin-right: 3px;
    }
    li {
      margin-bottom: 7px;
    }
  }

}


.page-area-copyright {
  padding: 2rem 0 10rem;
  .nav-pills li {
    padding-right: 10px;
    a {
      color: #7a7a7a;
      font-size: 14px;
      font-family: CenturyGothicStd,Open Sans,Arial,sans-serif;
      line-height: 39px;
    }
  }
  .address-list li {
    margin-bottom: 7px;
  }
  .nav-pills>li {
    float: left;
  }
  p {
    line-height: 39px;
    color: #7a7a7a;
    font-size: 14px;
  }
  .brand-foot {
    svg {
      width: 47px;
      height: 54.52px;
    }
  }
}

// Cookie Modal und Alert Overwrites
#cookieModal {

  .modal-footer {
      padding: 1.5rem 1rem;
  }

  .custom-switch .custom-control-label:before {
      height: 1.5rem;
      left: -3.5rem;
      width: 3rem;
      border-radius: 2rem;
  }

  .custom-switch .custom-control-label:after {
      left: calc(-3.5rem + 2px);
      width: calc(1.5rem - 4px);
      height: calc(1.5rem - 4px);
  }

  .custom-switch .custom-control-input:checked~.custom-control-label:after {
      transform: translateX(1.5rem);
  }

  .modal-header .close {
      padding: 2rem;
      font-size: 2rem;
  }

}

#cookieAlertWrapper {
  z-index: 999;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 16px;
  margin: auto;

  @include media-breakpoint-up(lg) {
    max-width: 50vw;
  }

  .close {
    padding-right: 1.75rem;
    font-size: 2rem;
  }

}

