// https://bootsnipp.com/snippets/eN3Q8
// http://jsfiddle.net/jme11/Fh47n/347/

// http://jsfiddle.net/Fh47n/
// https://codemyui.com/css-only-file-tree-structure-with-folder-file-icons-and-size-info/
// https://codepen.io/sazzad/pen/gEEKQb

.org-chart {
    --light-grey: #e8e8e8;
    --dark-grey: #666;
    --black: #000;
    --red: #c5282c;

    --text-color: var(--dark-grey);
    --text-active-color: var(--black);
    --border-color: var(--light-grey);
    --item-active-color: var(--red);
    --border: .2rem solid var(--border-color);

    > ul {
        padding-left: 0;
    }

    li {
        list-style-type: none;
        position: relative;
        padding: 2rem .5rem 0 .5rem;


        // We will use ::before and ::after to draw the connectors
        &::before, &::after{
            content: '';
            position: absolute;
        }

        a {

            background-color: #e8e8e8;

            display: inline-block;
            border: var(--border);
            border-color: #8aa4a7;
            padding: .5rem 1rem;
            text-decoration: none;

            &:hover, &:focus {
                color: var(--text-active-color);
                border-color: var(--item-active-color);
            }
            &:focus {
                outline: none;
            }
        }
    }
}

.org-chart-desktop {
    display: none;

    @include media-breakpoint-up(lg) {
        display: block;
    }

    ul {
        position: relative;
        padding-top: 2rem;
        padding-left: 0;

        // Time to add downward connectors from parents
        ul::before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            border-left: var(--border);
            border-color: #555;
            width: 0;
            height: 2rem;
        }

        a {
            padding: 1rem;
        }

        &.horizontal {
            li {
                width: 100%;
                
                &:before {
                    display: block;
                    content: '';
                    border-top: var(--border);
                    border-color: #555;
                    border-right: none;
                    width: 2rem;
                    top: 1.8rem;
                }

                a {
                    position: absolute;
                    left: 50%;
                    transform: translateX(calc(-100% + -2rem));
                    z-index: 1;
                }
            }

            + ul {
                padding-top: 7rem;

                &:before {
                    height: 7rem;
                }
            }
        }
    }

    li {
        float: left;
        text-align: center;

        // We will use ::before and ::after to draw the connectors
        &::before, &::after {
            top: 0;
            right: 50%;
            width: 50%;
            height: 2rem;
            border-top: var(--border);
            border-color: #555;
        }
        &::after {
            right: auto;
            left: 50%;
            border-left: var(--border);
            border-color: #555;
        }

        // We need to remove left-right connectors from elements without any siblings
        &:only-child::after, &:only-child::before {
            display: none;
        }

        // Remove space from the top of single children
        &:only-child {
            padding-top: 0;
        }

        // Remove left connector from first child and right connector from last child
        &:first-child::before, &:last-child::after {
            border: 0 none;
        }

        // Adding back the vertical connector to the last nodes
        &:last-child::before {
            border-right: var(--border);
            border-color: #555;
            //border-radius: 0 .5rem 0 0;
        }
        &:first-child::after {
            //border-radius: .5rem 0 0 0;
        }
    }
}

.org-chart-mobile {
    overflow-x: scroll;

    @include media-breakpoint-up(lg) {
        display: none;
    }

    // Remove connectors before root
    > ul {
        > li {
            &::before,
            &::after {
                border: 0;
            }
        }
    }

    li {
        --horizontal-line-top: 3.5rem;

        // We will use ::before and ::after to draw the connectors
        &::before, &::after {
            left: -2rem;
            right: auto;
        }
        &::before {
            top: 0;
            bottom: 5rem;
            width: .1rem;
            height: 100%;
            border-left: var(--border);
        }
        &::after {
            top: var(--horizontal-line-top);
            width: 2.5rem;
            height: 2rem;
            border-top: var(--border);
        }

        // Remove connectors after last child
        &:last-child::before {
            height: var(--horizontal-line-top);
        }
    }
}


a.org-chart-link {

    background-color: #e8e8e8;

    display: inline-block;
    border: .2rem solid  #8aa4a7;
    text-decoration: none;
    padding: 1rem;

    &:hover, &:focus {
        color: #000;
        border-color: #c5282c;
    }
    &:focus {
        outline: none;
    }
}
