.component.component-download,
.component.component-downloads {

  ul {
    list-style-type: none;
    padding: 0;
    li {
      list-style-type: none;
    }
  }

  .download {

    color: $color-red;
    font-family: 'CenturyGothicStd-Bold';
    font-weight: 300;

    margin-left: 20px;
    display: inline-block;

    svg {
      margin-left: -20px;
      width: 12px;
      height: 16px;
      margin-top: -2px;
      margin-right: 3px;
    }

  }

  p {
    margin-left: 20px;
  }

}

.body-downloads .component {
  a.link-extern {
    display: inline-block;
    padding-bottom: 15px;
  }
  a.download {
    padding-bottom: 15px;
  }
}