.component.component-ctaButton {
  a {
    letter-spacing: .2rem;
    background-color: transparent;
    text-decoration: none;
    padding: 2rem 5rem;
    white-space: inherit;
    color: #333;
    width: 100%;
    border: 2px solid #333;
    border-radius: 15px;
    display: inline-block;
    text-align: center;
  }
}

.component.component-ctaButton.component-ctaButton-full {
  a {
    background-color: #fff;
  }
}